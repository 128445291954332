// FooterBanner.js
import React, { useState } from 'react';
import './FooterBanner.css'; // Assuming you have an external CSS file for styles
import logo from '../images/logo.png'; // Import the logo image, adjust the path as needed

const FooterBanner = () => {
  const [isVisible, setIsVisible] = useState(true); // State to manage banner visibility

  const handleClick = () => {
    window.location.href = "https://publicinsights.uk"; // Direct navigation
  };

  const handleClose = () => {
    setIsVisible(false); // Hide the banner
  };

  if (!isVisible) return null; // Don't render the banner if it's not supposed to be visible

  return (
    <div className="banner">
      <div className="content">
        <img src={logo} alt="Cradle Logo" className="logo" />
        <div>
          Check out the new and improved site - <strong>CRADLE</strong> - for more features and better resources! <br />
          Use coupon code: <strong>REVPP20</strong> for 20% off for 12 months! Cancel any time!
        </div>
      </div>
      <button 
        onClick={handleClick} 
        style={{
          backgroundColor: '#4a148c', // Dark purple
          color: 'white',
          padding: '10px 20px',
          textDecoration: 'none',
          borderRadius: '5px',
          border: 'none',
          transition: 'background-color 0.3s',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
      >
        Visit Now!
      </button>
      <button 
        onClick={handleClose} 
        style={{
          marginLeft: '20px',
          backgroundColor: 'transparent',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          lineHeight: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        ×
      </button>
    </div>
  );
}

export default FooterBanner;
